import { css } from '@emotion/core'
import React from 'react'
import { EmotionStyles } from '~/types/types'
import { GRAY_MEDIUM_DARK } from '../../../constants/theme.styles'

const subheadingBaseStyles = css`
  font-weight: 400;
  line-height: 1.2em;
  color: ${GRAY_MEDIUM_DARK};
  text-transform: none;
`

interface Props {
  children?: React.ReactNode
  className?: string
  customCss?: EmotionStyles
}

const HeadingSubheading = ({ children, className = '', customCss, ...rest }: Props) => {
  return (
    <div
      className={`heading-subheading ${className}`}
      css={[subheadingBaseStyles, customCss ? customCss : null]}
      {...rest}
    >
      {children}
    </div>
  )
}

export default HeadingSubheading
