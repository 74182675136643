import React from 'react'
import { css, SerializedStyles } from '@emotion/core'
import HeadingSubheading from './HeadingSubheading'
import { TABLET_OR_MORE, BLACK, PURPLE, PURPLE_DARK } from '../../../constants/theme.styles'
import { EmotionStyles } from '~/types/types'

let headingBaseStyles = css`
  margin: calc(3.2rem - 0.14285714em) 0 1.6rem;
  font-weight: 600;
  line-height: 1.28571429em;
`
// ELEMENT TYPE STYLES
interface IHeadingTypeStyles {
  h1: SerializedStyles
  h2: SerializedStyles
  h3: SerializedStyles
  h4: SerializedStyles
  h5: SerializedStyles
  h6: SerializedStyles
  p: SerializedStyles
}

interface ISizeStyles {
  massive: SerializedStyles
  huge: SerializedStyles
  big: SerializedStyles
  large: SerializedStyles
  medium: SerializedStyles
  small: SerializedStyles
  tiny: SerializedStyles
  mini: SerializedStyles
}

interface ISpacingStyles {
  none: SerializedStyles
  relaxed: SerializedStyles
  veryRelaxed: SerializedStyles
  loose: SerializedStyles
}

interface IColorStyles {
  white: SerializedStyles
  black: SerializedStyles
  purple: SerializedStyles
  purpleDark: SerializedStyles
}

const headingTypeStyles: IHeadingTypeStyles = {
  h1: css`
    font-size: 3.2rem;
    .heading-subheading {
      font-size: 1.8rem;
    }
  `,
  h2: css`
    font-size: 2.7rem;
    .heading-subheading {
      font-size: 1.8rem;
    }
  `,
  h3: css`
    font-size: 2rem;
    .heading-subheading {
      font-size: 1.6rem;
    }
  `,
  h4: css`
    font-size: 1.8rem;
    .heading-subheading {
      font-size: 1.6rem;
    }
  `,
  h5: css`
    font-size: 1.6rem;
    .heading-subheading {
      font-size: 1.4rem;
    }
  `,
  h6: css`
    font-size: 1.4rem;
    .heading-subheading {
      font-size: 1.2rem;
    }
  `,
  p: css`
    font-size: 2.7rem;
    .heading-subheading {
      font-size: 1.8rem;
    }
  `
}

// SIZE STYLES
const sizeStyles: ISizeStyles = {
  // matches current massive main page titles
  massive: css`
    min-height: 1.6rem;
    font-size: 9rem;
    .heading-subheading {
      font-size: 1.8rem;
    }
  `,

  // matches about page at 2.5rem headers
  huge: css`
    min-height: 1.6em;
    font-size: 4rem;
    .heading-subheading {
      font-size: 1.8rem;
    }
  `,

  // matches h1
  big: headingTypeStyles.h1,

  // matches h2
  large: headingTypeStyles.h2,

  // matches h3
  medium: headingTypeStyles.h3,

  // matches h4
  small: headingTypeStyles.h4,

  // matches h5
  tiny: headingTypeStyles.h5,

  // matches h6
  mini: headingTypeStyles.h6
}

// spacing STYLES
const spacingStyles: ISpacingStyles = {
  // matches current massive main page titles
  none: css`
    margin-bottom: 0;
  `,
  relaxed: css`
    margin-bottom: 1.1em;
    @media ${TABLET_OR_MORE} {
      margin-bottom: 1.25em;
    }
  `,
  veryRelaxed: css`
    margin-bottom: 1.4em;
    @media ${TABLET_OR_MORE} {
      margin-bottom: 1.75em;
    }
  `,
  loose: css`
    margin-bottom: 1.8em;
    @media ${TABLET_OR_MORE} {
      margin-bottom: 2.25em;
    }
  `
}

// Color STYLES
const colorStyles: IColorStyles = {
  white: css`
    color: #fff;
  `,
  black: css`
    color: ${BLACK};
  `,
  purple: css`
    color: ${PURPLE};
  `,
  purpleDark: css`
    color: ${PURPLE_DARK};
  `
}

const uppercaseStyles = css`
  text-transform: uppercase;
`

type HeadingTagName = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p'
type Size = 'massive' | 'huge' | 'big' | 'large' | 'medium' | 'small' | 'tiny' | 'mini'
type Spacing = `none` | `relaxed` | `veryRelaxed` | `loose`
type Color = 'purple' | 'purpleDark' | 'black' | 'white'

interface IHeadingProps {
  /** The element to use for the heading */
  as?: HeadingTagName
  /** The size of the heading */
  size?: Size
  spacing?: Spacing
  color?: Color
  uppercase?: boolean
  className?: string
  style?: object
  children?: React.ReactNode
  customCss?: EmotionStyles
}

class Heading extends React.Component<IHeadingProps, never> {
  static Subheading = HeadingSubheading

  render() {
    const {
      as: Element = 'h2',
      color = 'black',
      size,
      spacing,
      children,
      uppercase,
      className = '',
      customCss,
      ...rest
    } = this.props

    const styles = [
      headingBaseStyles,
      headingTypeStyles[Element],
      size && sizeStyles[size],
      spacing && spacingStyles[spacing],
      color && colorStyles[color],
      uppercase ? uppercaseStyles : null,
      customCss ? customCss : null
    ]
    return (
      <Element className={`text-heading ${className}`} css={styles} {...rest}>
        {children}
      </Element>
    )
  }
}

export default Heading
